/* eslint-disable */

/**
 * Set up `analytics` variable and prepare to load Segment's analytics.js library
 * @see https://segment.com/docs/sources/website/analytics.js/quickstart/
 *
 * Segment Plugins:
 * https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/#plugin-architecture
 *
 * when this snippet is imported from Segment again, make sure that 'register' is added to analytics.methods, as it is necessary for the anonymizing IP plugin to work
 * https://github.com/segmentio/analytics-next/issues/589
 */
(function() {
    'use strict';

    // Create a queue, but don't obliterate an existing one!
    var analytics = window.analytics = window.analytics || [];

    // If the real analytics.js is already on the page return.
    if (analytics.initialize) return;

    // If the snippet was invoked already show an error.
    if (analytics.invoked) {
        if (window.console && console.error) {
            console.error('Segment snippet included twice.');
        }
        return;
    }

    // Invoked flag, to make sure the snippet
    // is never invoked twice.
    analytics.invoked = true;

    // A list of the methods in Analytics.js to stub.
    analytics.methods = [
        'trackSubmit',
        'trackClick',
        'trackLink',
        'trackForm',
        'pageview',
        'identify',
        'reset',
        'group',
        'track',
        'ready',
        'alias',
        'debug',
        'page',
        'once',
        'off',
        'on',
        'register' // when this snippet is imported from Segment again, make sure that 'register' is added, as it is necessary for the anonymizing IP plugin to work
    ];

    // Define a factory to create stubs. These are placeholders
    // for methods in Analytics.js so that you never have to wait
    // for it to load to actually record data. The `method` is
    // stored as the first argument, so we can replay the data.
    analytics.factory = function(method){
        return function(){
            var args = Array.prototype.slice.call(arguments);
            args.unshift(method);
            analytics.push(args);
            return analytics;
        };
    };

    // For each of our methods, generate a queueing stub.
    for (var i = 0; i < analytics.methods.length; i++) {
        var key = analytics.methods[i];
        analytics[key] = analytics.factory(key);
    }

    // Define a method to load Analytics.js from our CDN,
    // and that will be sure to only ever load it once.
    analytics.load = function(key, options){
        // Create an async script element based on your key.
        var script = document.createElement('script');
        script.type = 'text/javascript';
        script.async = true;
        script.src = ('https:' === document.location.protocol
            ? 'https://' : 'http://')
            + 'cdn.segment.com/analytics.js/v1/'
            + key + '/analytics.min.js';

        // Insert our script next to the first script element.
        var first = document.getElementsByTagName('script')[0];
        first.parentNode.insertBefore(script, first);
        analytics._loadOptions = options;
    };

    // Segment plugin to anonymize IPs
    var ANONYMOUS_IP_ADDRESS = '0.0.0.0';

    var anonymizeIP = function (ctx) {
        var event = ctx.event;
        event.context = event.context || {};

        // do not gather user IP until we're sure they've consented (window.cookieConsentAllowed is true)
        if (!window.cookieConsentAllowed) {
            event.context.ip = ANONYMOUS_IP_ADDRESS;
        }

        return ctx;
    };

    window.analytics.register({
        name: 'IP Anonymizer',
        version: '1.0.0',
        type: 'enrichment',
        isLoaded: function () {
            return true;
        },
        load: function() {},
        page: anonymizeIP,
        track: anonymizeIP,
        identify: anonymizeIP
    });

    // Add a version to keep track of what's in the wild.
    window.analytics.SNIPPET_VERSION = '4.15.3';
})();
