/* eslint-disable complexity */
/* eslint-disable new-cap */
/* global OneTrust, document, window, CustomEvent */
(function () {
    'use strict';

    var eventDispatcher = document.querySelector('html');
    var oneTrustInitDone = false;

    // The OneTrust 'otBannerSDK.js' script will call this function when it loads and when consent changes
    window.OptanonWrapper = function () {
        let OneTrustDomainData = OneTrust.GetDomainData();
        let cookiePolicyButton = document.getElementsByClassName('ot-sdk-show-settings')[0];

        window.isCookiePolicyButtonDisplayed = false;

        // checking if we should, or should not, display the cookie policy button
        // we only want to display the button if we are in GDPR(+UK) and CCPA(California)
        // Default audience (outside of GDRP and CCPA) is set as 'opt-out'
        // with all cookie consent options prechecked
        // GDRP and CCPA are set as opt-in
        if (OneTrustDomainData && OneTrustDomainData.ConsentModel.Name === 'opt-in') {
            window.isCookiePolicyButtonDisplayed = true;
        }

        cookiePolicyButton.style.display = 'none';

        if (window.isCookiePolicyButtonDisplayed) {
            cookiePolicyButton.style.display = 'flex';
        }

        OneTrust.OnConsentChanged(function () {
            eventDispatcher.dispatchEvent(new CustomEvent('jamf.oneTrustConsentChange', {
                detail: {
                    action: 'selective-allow'
                }
            }));
        });

        // On initial page load (first time OptanonWrapper() fires), handle this specially
        if (!oneTrustInitDone) {
            oneTrustInitDone = true;

            // If consents weren't already managed - first pop up user will see visiting the page
            var action = 'init';

            if (OneTrust.IsAlertBoxClosed()) {
                // if consent was already managed - no pop up will appear as user already consent
                action = 'consented';
            }

            eventDispatcher.dispatchEvent(new CustomEvent('jamf.oneTrustConsentLoad', {
                'detail': {
                    action: action,
                    origin: 'OneTrust'
                }
            }));
        }
    };
})();
