/* eslint-disable complexity */
/* eslint-disable new-cap */
/* global ConfigurationResolver, document, window */

(function () {
    'use strict';

    var settingsButton = null;
    var jamfSegmentScriptSelector;

    if (document.querySelector('[src*="skilljar"]')) {
        jamfSegmentScriptSelector = document.querySelector('[src*="skilljar"]');
    }

    if (document.querySelector('[src*="salesforce-accounts"]')) {
        jamfSegmentScriptSelector = document.querySelector('[src*="salesforce-accounts"]');
    }

    if (document.querySelector('[src*="jamf-segment"]')) {
        jamfSegmentScriptSelector = document.querySelector('[src*="jamf-segment"]');
    }

    var jamfSegmentScriptDataset;
    var oneTrustKey;
    var cookieConsentVersion = null;
    var isOneTrustAllowed = true;

    if (jamfSegmentScriptSelector && jamfSegmentScriptSelector.dataset) {
        jamfSegmentScriptDataset = jamfSegmentScriptSelector.dataset;
    }

    if (window.ConfigurationResolver) {
        // for jamf.com page, ConfigurationResolver will be present
        oneTrustKey = ConfigurationResolver.get('onetrustKey');
        cookieConsentVersion = ConfigurationResolver.get('cookieVersion');
        isOneTrustAllowed = ConfigurationResolver.get('feature:otcc');
    } else if (jamfSegmentScriptDataset && jamfSegmentScriptDataset.onetrustKey && jamfSegmentScriptDataset.cookieconsentVersion) {
        // for 3rd party pages - no ConfigurationResolver present, we will read from dataset
        oneTrustKey = jamfSegmentScriptDataset.onetrustKey;
        cookieConsentVersion = jamfSegmentScriptDataset.cookieconsentVersion;
    } else {
        // no datasets or ConfigurationResolver so we just return
        return;
    }

    if (cookieConsentVersion === null || cookieConsentVersion === '1') {
        isOneTrustAllowed = false;
    }

    // eslint-disable-next-line no-magic-numbers
    // OneTrustDomainData.ConsentModel.Name of 'opt-in' is only deigned for GDPR and CCPA cookies, 'opt-out' is defined for Default audience only,
    // as was agreed we do not want to display the button for Default audience
    if (cookieConsentVersion === '2' && oneTrustKey && isOneTrustAllowed) {
    // append settings button to always be visible .j-cookie-settings-btn.ot-sdk-show-settings - this is only for OneTrust
        if (!window.ConfigurationResolver || !document.querySelector('.ot-sdk-show-settings')) {
            settingsButton = document.createElement('button');
            settingsButton.setAttribute('class', 'ot-sdk-show-settings j-custom-ot-btn');
            document.querySelector('body').appendChild(settingsButton);
        }
    }
})();
