/* eslint-disable new-cap */
/* eslint-disable complexity */

/* global ga, analytics, OnetrustActiveGroups, OneTrust, window, document, console, deleteJamfCookies */

/*
Usage:
    Include this script at the bottom of any page to make it GDPR & CCPA compliant
    <script src="https://www.jamf.com/js-dist/jamf-segment.js" data-segment-key="ckdBuOEFZSwaeSLP7r0TODkYwtfnHOkV" data-segment-gtm-container-id="yourContainerIdHere"></script>

Options:
    data-segment-key="yourSegmentKey" - Required
        The Segment key will be unique for for each domain this script is included on.
    data-segment-enable-cross-domain-analytics="true" - Optional
        If you want to use cross domain analytics.
    data-segment-gtm-container-id="yourContainerIdHere" - Optional
        Forward tracking events to Google Tag Manager
    data-cookieconsent-version="2" - Optional
        not having this option, or having it set to 1 will load the old cookie manager,
        if you set it to 2 it will load the new cookie consent - OneTrust
    data-onetrust-key="" Required if using OneTrust

    if you need a custom button to open cookie settings, it's enough to create one with class="ot-sdk-show-settings j-custom-ot-btn",
    by default OneTrust will fill it up with text "Cookie Settings"
    https://about.gitlab.com/handbook/marketing/digital-experience/onetrust-cookie-consent/#adding-a-button-to-the-footer
*/

(function () {

    window.analyticsClientId = null;
    // array of selected active groups coming from OneTrust, if a group is listed here means it was selected [groups look like C0002, C0004, C0005]
    window.activeCategories = null;
    window.OneTrustSegmentOptions = {};
    window.trackingScriptLoaded = false;
    window.cookieConsentAllowed = false;

    const $element = document.querySelector('html');
    const strictlyNecessaryCookiesOneTrustCategory = 'C0001';
    const jamfSegmentSrc = '[src*="jamf-segment"]';
    const skilljarScr = '[src*="skilljar"]';
    const salesforceAccountsSrc = '[src*="salesforce-accounts"]';

    let $jamfSegmentScriptSelector;
    let dataset;
    let segmentKey;
    let isCrossDomainAnalyticsEnabled;
    let googleTagManagerContainerId;
    let segmentDestinations;

    if (document.querySelector(skilljarScr)) {
        $jamfSegmentScriptSelector = document.querySelector(skilljarScr);
    }

    if (document.querySelector(salesforceAccountsSrc)) {
        $jamfSegmentScriptSelector = document.querySelector(salesforceAccountsSrc);
    }

    if (document.querySelector(jamfSegmentSrc)) {
        $jamfSegmentScriptSelector = document.querySelector(jamfSegmentSrc) || null;
    }

    if ($jamfSegmentScriptSelector && $jamfSegmentScriptSelector.dataset) {
        dataset = $jamfSegmentScriptSelector.dataset;
        segmentKey = dataset.segmentKey;
        isCrossDomainAnalyticsEnabled = (dataset.segmentEnableCrossDomainAnalytics && dataset.segmentEnableCrossDomainAnalytics === 'true') || false;
        googleTagManagerContainerId = dataset.segmentGtmContainerId || false;
    }

    if (!segmentKey) {
        console.error('Segment Key is not defined.');
    }

    // Banner has been interacted with by the user and accepted if they are subjected to the law,
    // or the user is in the US, or another non-subject country, so immediately approve cookies.
    $element.addEventListener('jamf.cookieConsentAllow', function (event) {
        window.cookieConsentAllowed = true;
        initSegment(event, initializeGoogleAnalytics);
        allowCookieUsage();
    });

    // Banner has been interacted with and user explicitly denied cookies.
    $element.addEventListener('jamf.cookieConsentDeny', function (event) {
        window.cookieConsentAllowed = false;
        initSegment(event, initializeGoogleAnalytics);
        denyCookieUsage();
    });

    // Banner has been displayed, but user has not interacted with it.
    $element.addEventListener('jamf.cookieConsentLoad', function (event) {
        initSegment(event, initializeGoogleAnalytics);
    });

    // Banner has been displayed, but user has not interacted with it - OneTrust consent
    $element.addEventListener('jamf.oneTrustConsentLoad', function (event) {
        splitOneTrustActiveGroups();
        fetchSegmentDestinations(event);
    });

    // OneTrust cookie consent selection were changed by the user
    $element.addEventListener('jamf.oneTrustConsentChange', function (event) {
        splitOneTrustActiveGroups();
        fetchSegmentDestinations(event);
    });

    // load Segment analytics
    analytics.load(segmentKey);

    analytics.on('track', function (event, properties) {
        sendToGoogleTagManager(event, properties);
    });

    analytics.on('page', function (event, properties) {
        sendToGoogleTagManager(event, properties);
    });

    // get OneTrust Active Groups - currently selected cookie consent categories
    const splitOneTrustActiveGroups = function () {
        window.activeCategories = OnetrustActiveGroups.split(',').filter(function (group) {
            return group !== null && group !== '' && group.startsWith('C');
        });
    };

    let allowCookieUsage = function () {
        analytics.track('Accepted Cookie Usage', {
            category: 'Actions',
            label: window.location.href
        });
    };

    let denyCookieUsage = function () {
        analytics.track('Declined Cookie Usage', {
            category: 'Actions',
            label: window.location.href
        });
    };

    const defaultSegmentOptions = {
        integrations: {
            'All': false,
            'Google Analytics': true
        }
    };

    const trackableSegmentOptions = {
        integrations: {
            'All': true
        }
    };

    /**
     * This function sends data to Segment about:
     * - what page user is visiting
     * - what integrations user has turn on/off based on the cookie consent decision
     * - identifies user based on previous user activity (also uses Marketo to get user info)
     * - initializing some google analytics settings
     * @param {Event} event
     */
    const initSegment = function (event = null) {
        let eventAction = null;

        if (event) {
            eventAction = (event.detail && event.detail.action) || null;
        }

        initializeGoogleAnalytics();

        // Make our initial page hit call to Segment/GA
        if (eventAction === 'init' || eventAction === 'consented') {
            if (window.activeCategories && window.activeCategories.length > 0) {
                analytics.page(null, null, {}, window.OneTrustSegmentOptions);
            } else if (window.cookieConsentAllowed) {
                analytics.page(null, null, {}, trackableSegmentOptions);
            } else {
                analytics.page(null, null, {}, defaultSegmentOptions);
            }
        }

        analytics.reset();

        if (!window.cookieConsentAllowed) {
            return;
        }

        // change in the old cookie consent event
        if (eventAction === 'change' && !window.activeCategories) {
            analytics.page(null, null, {
                'path': '/filter-and-ignore/',
                'url': window.location.origin + '/filter-and-ignore/'
            }, trackableSegmentOptions);
        }

        // change in onetrust consent
        if (eventAction === 'selective-allow') {
            analytics.page(null, null, {
                'path': '/filter-and-ignore/',
                'url': window.location.origin + '/filter-and-ignore/'
            }, window.OneTrustSegmentOptions);
        }
    };

    const initializeGoogleAnalytics = function () {
        try {
            // Google advertising features
            if (window.cookieConsentAllowed) {
                ga('require', 'displayfeatures');
            } else {
                ga('require', 'displayfeatures', null);
            }

            if (isCrossDomainAnalyticsEnabled) {
                ga('require', 'linker');
                ga('linker:autoLink', 'www.jamf.com');
                ga(function (tracker) {
                    window.analyticsClientId = tracker.get('linkerParam');
                });
            }
        } catch (exception) {
            // Fail silently
        }
    };

    if (googleTagManagerContainerId) {
        if (typeof window.dataLayer === 'undefined') {
            window.dataLayer = window.dataLayer || [];
            // Push initial start time, per GTM docs https://developers.google.com/tag-manager/quickstart
            window.dataLayer.push({
                'gtm.start': new Date().getTime(),
                'event': 'gtm.js'
            });

            let script = document.createElement('script');

            script.async = true;
            script.src = 'https://www.googletagmanager.com/gtm.js?id=' + escape(googleTagManagerContainerId) + '&l=dataLayer';
            document.body.appendChild(script);
        }
    }

    /**
     * Subscribe to the global track and page events and send the same data to Google Tag Manager's dataLayer.
     * GTM is disabled in Segment, thus Segment can't make the calls to GTM for us.
     * We're loading gtm.js in jamf-tracking.js so we need to make the calls to dataLayer manually.
     *
     * @See https://github.com/segment-integrations/analytics.js-integration-google-tag-manager/blob/c65cea79132dd60825ddff1ae178f2e940845363/lib/index.js#L90-L98
     *
     * @param {string} event
     * @param {object} properties
     */
    let sendToGoogleTagManager = function (event, properties) {
        if (!window.cookieConsentAllowed) {
            return;
        }

        try {
            /* eslint no-param-reassign: 0 */
            if (properties === null || typeof properties !== 'object') {
                properties = {};
            }

            let userId = analytics.user().id();
            let anonymousId = analytics.user().anonymousId();

            if (userId) {
                properties.userId = userId;
            }
            if (anonymousId) {
                properties.segmentAnonymousId = anonymousId;
            }
            properties.event = event;

            window.dataLayer.push(properties);
        } catch (error) {
            // fail silently
        }
    };

    /**
     * fetching Segment Destinations from https://cdn.segment.com/v1/projects/${segmentKey}/integrations
     * checking if integrations match with destinationsMap cookies
     * @param {*} event
     */
    const fetchSegmentDestinations = async function (event = null) {
        let destinationsMap = createDestinationsMap();

        const res = await window.fetch(`https://cdn.segment.com/v1/projects/${segmentKey}/integrations`).then((response) => {
            if (response.ok) {
                return response;
            }
            throw new Error('Something went wrong');
        })
        .catch((error) => {
            console.log(error);
            const defaultSegmentCdnResponse = [
                {
                    creationName: 'Marketo'
                },
                {
                    creationName: 'Google Analytics'
                },
                {
                    creationName: 'Actions Google Analytic 4'
                }
            ];

            return defaultSegmentCdnResponse;
        });

        let response = await res.json();

        // Rename creationName to id to abstract the weird data model
        for (let destination of response) {
            destination.id = destination.creationName;
            delete destination.creationName;
        }

        segmentDestinations = response;

        let destinationPreferences = segmentDestinations.map(function (dest) {
            if (window.activeCategories.includes(destinationsMap[dest.id])) {
                return {
                    [dest.id]: true
                };
            }

            return {
                [dest.id]: false
            };
        });

        let destinationPreferencesReduced = Object.assign({
            'All': false
        }, ...destinationPreferences);

        window.OneTrustSegmentOptions = {
            integrations: destinationPreferencesReduced
        };

        oneTrustCookieChangeOrLoad(event);
    };

    /**
     * function for OneTrust cookie consent, checking if cookies were declined, accepted or partially accepted
     * runs on every OneTrust cookie consent change or page load
     * @param {*} event
     */
    const oneTrustCookieChangeOrLoad = function (event = null) {

        const strictlyNecessaryCookiesEnabled = Object.values(window.activeCategories).includes(strictlyNecessaryCookiesOneTrustCategory);

        // cookies at least partially accepted
        if ((strictlyNecessaryCookiesEnabled && window.activeCategories.length > 1) || (!strictlyNecessaryCookiesEnabled && window.activeCategories.length > 0)) {
            window.cookieConsentAllowed = true;
            initSegment(event);

            // for the change action only
            if (event && event.detail && event.detail.action === 'selective-allow') {

                // GA3 Event Tracker
                analytics.track(
                    'Selective Allow of Cookie Usage',
                    {
                        category: 'Actions',
                        label: window.location.href,
                        options: window.activeCategories
                    }, window.OneTrustSegmentOptions
                );

                // GA4 Event Tracker
                analytics.track(
                    'cookie_accept',
                    {},
                    window.OneTrustSegmentOptions
                );
            }
        }

        // only strictly necessary cookies accepted, treated pretty much like cookies declined
        if ((strictlyNecessaryCookiesEnabled && window.activeCategories.length === 1) || (!strictlyNecessaryCookiesEnabled && window.activeCategories.length === 0)) {
            initSegment(event);

            // for the change action only
            if (event && event.detail && event.detail.action === 'selective-allow') {
                window.cookieConsentAllowed = false;

                // GA3 Event Tracker
                analytics.track(
                    'Declined All OneTrust Cookies',
                    {
                        category: 'Actions',
                        label: window.location.href
                    }, window.OneTrustSegmentOptions
                );

                // GA4 Event Tracker
                analytics.track(
                    'cookie_decline',
                    {},
                    window.OneTrustSegmentOptions
                );
            }
        }

        deleteJamfCookies();
    };

    /**
     * Create a destinations map based on OneTrust.
     * in OneTrust dashboard we add special custom cookies, that match the Segment destinations creationNames: https://cdn.segment.com/v1/projects/{segmentID}/integrations
     * we assign these cookies to cookies group (performance/targeting/functional/strictly necessary) that we want to associate with the Segment Integration
     * here, we take all first party cookies from Segment, and create an object with the cookie name -> cookie group
     * like: {Actions Google Analytic 4: "C0002", Google Analytics: "C0002, Marketo: "C0004"}
     *
     * @returns {object} destinations map
     */
    const createDestinationsMap = function () {
        if (typeof OneTrust.GetDomainData !== 'function') {
            return {};
        }

        let domainData = OneTrust.GetDomainData();

        if (!Array.isArray(domainData.Groups)) {
            return {};
        }

        let destinationsMap = {};

        domainData.Groups.forEach(function (group) {
            return group.FirstPartyCookies.forEach(function (integration) {
                destinationsMap[integration.Name] = group.CustomGroupId;
            });
        });

        return destinationsMap;
    };
})();
