/* eslint-disable no-unused-vars */
/* global window, document, localStorage, setTimeout, Optanon, OnetrustActiveGroups  */

var deleteJamfCookies = function () {
    // Delete cookies on init and any change if they are not matching OnetrustActiveGroups
    var otDomainGrps = JSON.parse(JSON.stringify(Optanon.GetDomainData().Groups));

    // Set timeout to give 3rd party scripts a chance to load and add cookies
    setTimeout( function () {

        for (var i = 0; i < otDomainGrps.length; i++) {
            var group = otDomainGrps[i];

            if (!OnetrustActiveGroups.includes(group['CustomGroupId'])) {
                for(var j=0; j < group['Cookies'].length; j++){
                    deleteCookiesByName(group['Cookies'][j]['Name']);
                }
            }
        }
    }, 1000);
}

var deleteCookiesByName = function (name) {
    // Delete root path cookies
    var domainName = window.location.hostname;
    var jamfDomain = 'www.jamf.com';
    var jamfDomainAllSubdomains = '.jamf.com';
    var jamfnebulaDomainAllSubdomains = '.jamfnebula.com';

    deleteCookie(name, '/');
    deleteCookie(name, '/', domainName);
    deleteCookie(name, '/', '.' + domainName);
    // Subdomains sometimes use cookies assigned to main domain
    deleteCookie(name, '/', jamfnebulaDomainAllSubdomains);
    deleteCookie(name, '/', jamfDomainAllSubdomains);
    deleteCookie(name, '/', jamfDomain);

    // Delete LSO incase LSO being used
    localStorage.removeItem(name);

    // Check for the current path of the page
    var pathArray = window.location.pathname.split('/');
    // Loop through path hierarchy and delete potential cookies at each path.
    for (var i=0; i < pathArray.length; i++){
        if (pathArray[i]){
            // Build the path string from the Path Array e.g /site/login
            var currentPath = pathArray.slice(0,i+1).join('/');
            deleteCookie(name, currentPath);
            deleteCookie(name, currentPath, domainName);
            deleteCookie(name, currentPath, '.' + domainName);
            // Subdomains sometimes use cookies assigned to main domain
            deleteCookie(name, currentPath, jamfnebulaDomainAllSubdomains);
            deleteCookie(name, currentPath, jamfDomainAllSubdomains);
            deleteCookie(name, currentPath, jamfDomain);

            // Maybe path has a trailing slash!
            deleteCookie(name, currentPath + '/');
            deleteCookie(name, currentPath + '/', domainName);
            deleteCookie(name, currentPath + '/', '.' + domainName);
            // Subdomains sometimes use cookies assigned to main domain
            deleteCookie(name, currentPath + '/', jamfnebulaDomainAllSubdomains);
            deleteCookie(name, currentPath + '/', jamfDomainAllSubdomains);
            deleteCookie(name, currentPath + '/', jamfDomain);
        }
    }
};

var deleteCookie = function (name, path, domain) {
    domain = domain || null;

    var cookie = name + '=; Max-Age=-99999999; Path=' + path + ';';

    if (domain) {
        cookie = cookie + 'Domain=' + domain;
    }

    document.cookie = cookie;
};
